import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getStartOfUTCDay } from './wordList';

// Cache for archive data
let archiveCache: {
  data: ArchiveEntry[] | null;
  timestamp: number | null;
} = {
  data: null,
  timestamp: null
};

interface ArchiveEntry {
  date: string;
  firstWord: string;
  lastWord: string;
  timestamp: number;
  puzzleNumber: number;
}

interface ArchiveResponse {
  type: 'archive';
  archive: ArchiveEntry[];
}

function Archive() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [archiveEntries, setArchiveEntries] = useState<ArchiveEntry[]>([]);
  
  // Add keyframe animation
  const rotateKeyframe = `
    @keyframes rotate {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  `;
  
  useEffect(() => {
    const fetchArchive = async () => {
      const now = new Date();
      const todayStart = getStartOfUTCDay(now);

      // If we have cached data from today, use it
      if (archiveCache.data && archiveCache.timestamp === todayStart.getTime()) {
        setArchiveEntries(archiveCache.data);
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch('/api/wordchains?type=archive');
        if (!response.ok) {
          throw new Error('Failed to fetch archive');
        }
        const data: ArchiveResponse = await response.json();
        
        // Cache the response
        archiveCache = {
          data: data.archive,
          timestamp: todayStart.getTime()
        };
        
        setArchiveEntries(data.archive);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching archive:', error);
        setError('Failed to load archive. Please try again later.');
        setIsLoading(false);
      }
    };
    fetchArchive();
  }, []);

  const isDarkMode = localStorage.getItem('isDarkMode') === 'true';

  const handlePuzzleClick = (timestamp: number) => {
    // Store the test date in localStorage
    localStorage.setItem('testDate', timestamp.toString());
    // Set flag for archived puzzle
    localStorage.setItem('isArchivedPuzzle', 'true');
    
    // Use separate keys for archived puzzles
    const keysToRemove = [
      `archived_gameOver_${timestamp}`,
      `archived_isWin_${timestamp}`,
      `archived_turnsLeft_${timestamp}`,
      `archived_lastPlayed_${timestamp}`,
      `archived_gameWords_${timestamp}`,
      `archived_incorrectWords_${timestamp}`,
      `archived_letterResults_${timestamp}`,
      `archived_showColors_${timestamp}`,
      `archived_hasSubmitted_${timestamp}`,
      `archived_revealedCorrectLetters_${timestamp}`,
      `archived_letterAttempts_${timestamp}`
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
    
    // Navigate to the main game
    navigate('/');
  };

  return (
    <div style={{
      backgroundColor: isDarkMode ? '#1a1a1a' : '#f8edeb',
      minHeight: '100vh',
      padding: '20px',
      color: isDarkMode ? '#ffffff' : '#000000'
    }}>
      <style>{rotateKeyframe}</style>
      <div style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '40px'
        }}>
          <h1 style={{ margin: 0 }}>Archive</h1>
          <Link
            to="/"
            onClick={() => {
              localStorage.removeItem('testDate');
              localStorage.removeItem('isArchivedPuzzle');
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '8px',
              backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
              color: isDarkMode ? '#ffffff' : '#000000',
              textDecoration: 'none',
              fontWeight: 'bold'
            }}
          >
            Today's Puzzle
          </Link>
        </div>

        {isLoading ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh'
          }}>
            <img 
              src="/loader.png" 
              alt="Loading archive..." 
              style={{
                width: '48px',
                height: '48px',
                animation: 'rotate 1s linear infinite',
                transformOrigin: 'center'
              }}
            />
          </div>
        ) : error ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            fontSize: '24px',
            color: 'red'
          }}>
            {error}
          </div>
        ) : (
          <div style={{
            display: 'grid',
            gap: '16px'
          }}>
            {archiveEntries.map((entry, index) => (
              <div
                key={index}
                onClick={() => handlePuzzleClick(entry.timestamp)}
                style={{
                  backgroundColor: isDarkMode ? '#2d2d2d' : 'white',
                  padding: '20px',
                  borderRadius: '12px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = 'translateY(-2px)';
                  e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = 'none';
                  e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
                }}
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '12px'
                }}>
                  <h3 style={{ margin: 0 }}>
                    {entry.date} • #{entry.puzzleNumber}
                  </h3>
                  <div style={{
                    padding: '4px 8px',
                    borderRadius: '4px',
                    backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
                    fontSize: '14px',
                    fontWeight: 'bold'
                  }}>
                    Play
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  gap: '8px',
                  flexWrap: 'wrap',
                  fontSize: '14px',
                  color: isDarkMode ? '#cccccc' : '#666666'
                }}>
                  <span>{entry.firstWord}</span>
                  <span>→</span>
                  <span>{entry.lastWord}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Archive;
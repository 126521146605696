// Helper function to get consistent start of day at EST midnight
export const getStartOfUTCDay = (date: Date): Date => {
  // Convert to EST (UTC-5) by adding 5 hours to get correct day boundary
  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    5, 0, 0, 0  // 5am UTC = 12am EST
  ));
};

interface PuzzleResponse {
  type: 'puzzle';
  puzzle: {
    words: string[];
    timestamp: number;
    puzzleNumber: number;
  };
}

// Cache for puzzle data
const puzzleCache: Record<string, string[]> = {};

export async function getWordsForDate(date: Date): Promise<string[]> {
  // Normalize the timestamp to start of day
  const normalizedDate = getStartOfUTCDay(date);
  const cacheKey = normalizedDate.getTime().toString();
  
  // Check cache first
  if (puzzleCache[cacheKey]) {
    return puzzleCache[cacheKey];
  }

  try {
    // Still use original timestamp for API call to maintain compatibility
    const timestamp = date.getTime().toString();
    const response = await fetch(`/api/wordchains?date=${timestamp}`);
    if (!response.ok) {
      throw new Error('Failed to fetch puzzle');
    }
    const data: PuzzleResponse = await response.json();
    
    // Cache the response using normalized timestamp
    puzzleCache[cacheKey] = data.puzzle.words;
    
    return data.puzzle.words;
  } catch (error) {
    console.error('Error fetching puzzle:', error);
    throw error;
  }
}

export function getNextGameTime(): Date {
    const now = new Date();
    const tomorrow = getStartOfUTCDay(new Date(now));
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
    return tomorrow;
}

export function getTimeUntilNextGame(): number {
    const now = new Date();
    const nextGame = getNextGameTime();
    return nextGame.getTime() - now.getTime();
}
